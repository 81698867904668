import SpreadsheetCell from "../SpreadsheetCell"
import htmx from "htmx.org"

export const SPREADSHEET_ACTION_PANE_ID = 'spreadsheet-action-pane'

export const registerHtmx = (window) => {
    window.htmx = htmx;
}

const getCellEditUrl = (cell: SpreadsheetCell) => {
    const row = cell.row;

    // console.log('aaaa', row)
    const urlElement = document.querySelector(`[data-row='${row}'][data-col='1']`) as HTMLElement;
    // console.log('aaaa', urlElement, urlElement.dataset, urlElement.dataset.url)
    let urlString = '';
    if (urlElement && urlElement.dataset) {
        urlString = urlElement.dataset.url || '';
    }
    if (urlString === '') {
        console.log('ERROR: No URL found for row ' + row)
    }

    return urlString;
}



const drawLine = (cell, ref_cell) => {
    const cellRect = cell.getBoundingClientRect();
    const refCellRect = ref_cell.getBoundingClientRect();

    // Get the table's bounding rectangle.
    const table = document.querySelector('#spreadsheet-table');
    if (!table) throw new Error('Table not found');
    const tableRect = table.getBoundingClientRect();

    const cellCenter = {
        x: cellRect.left - tableRect.left + cellRect.width / 2,
        y: cellRect.top - tableRect.top + cellRect.height / 2,
    };

    const refCellCenter = {
        x: refCellRect.left - tableRect.left + refCellRect.width / 2,
        y: refCellRect.top - tableRect.top + refCellRect.height / 2,
    };

    // Create a div to represent the line.
    const line = document.createElement('div');
    line.classList.add('reference-line');
    line.style.position = 'absolute';
    line.style.zIndex = '9999';
    line.style.backgroundColor = 'black';
    line.style.height = '2px';

    // Set the position and length of the line.
    line.style.left = `${cellCenter.x}px`;
    line.style.top = `${cellCenter.y}px`;
    line.style.width = `${Math.hypot(cellCenter.x - refCellCenter.x, cellCenter.y - refCellCenter.y)}px`;

    // Set the rotation of the line.
    const angle = Math.atan2(refCellCenter.y - cellCenter.y, refCellCenter.x - cellCenter.x) * 180 / Math.PI;
    line.style.transform = `rotate(${angle}deg)`;
    line.style.transformOrigin = 'left center';

    // Append the line to the table instead of the body.
    table.appendChild(line);
}


  export const editCell = (cell: SpreadsheetCell) => {
    let url = getCellEditUrl(cell)

    // @ts-ignore
    const dependencyMap = Alpine.store('sheet').dependencyMap;
    console.log('dependencyMap...2', dependencyMap)


    // console.log('dependencies...2', dependencies)
    const key = `${cell.row},${cell.column}`
    // const dependencies = dependencyMap[`${cell.row},${cell.column}`];

    // console.log('editCell', cell)
    // const ref_cell_el = document.querySelector(`[x-cell][data-row="4"][data-col="4"]`);
    // const cell_el = document.querySelector(`[x-cell][data-row="${cell.row}"][data-col="${cell.column}"]`);
    // // ref_cell_el.style.backgroundColor = 'red';
    // console.log('ref_cell_el', ref_cell_el);
    if (dependencyMap.hasOwnProperty(key)) {
      let dependencies = dependencyMap[key];
      console.log('Dependent cells array for ', key, dependencies); // Outputs: [["a"], ["b"]]
      dependencies.forEach(([depRow, depCol]) => {
        let cell_el = document.querySelector(`[x-cell][data-row="${cell.row}"][data-col="${cell.column}"]`);
        let ref_cell_el = document.querySelector(`[x-cell][data-row="${depRow}"][data-col="${depCol}"]`);
        drawLine(cell_el, ref_cell_el);
      });

    } else {
      console.log('Key not found in dependencyMap for ', key);
    }

    // note: url already has a param for url_prefix
    url = `${url}&formula_column=${cell.column}`

    console.log('url', url)
    window.htmx.ajax(
      'GET',
      url,
      {
        target:'#spreadsheet-action-pane',
        swap:'innerHTML',
      }
    ).then( () => {

      // focus first input, if possible
      const actionPaneEl = document.getElementById(SPREADSHEET_ACTION_PANE_ID) as HTMLElement;
      if (actionPaneEl != null) {
        const targetDiv = actionPaneEl.querySelector('div[x-rowkey^="STANDARD_SCHEDULE_ITEM___"]:not([style*="display: none"])');
        const targetScheduleDiv = actionPaneEl.querySelector('div[x-rowkey^="STANDARD_SCHEDULE___"]:not([style*="display: none"])');

        if (targetDiv) {
            const inputOrSelect = targetDiv.querySelector('input:not([type=hidden]), select');
            if (inputOrSelect) {
                inputOrSelect.focus();
            }
        } else if (targetScheduleDiv) {
            const inputOrSelect = targetScheduleDiv.querySelector('input:not([type=hidden]), select');
            if (inputOrSelect) {
                inputOrSelect.focus();
            }
        }
      }
    });
}


export const cancelCellEdit = () => {
    const actionPaneEl = document.getElementById(SPREADSHEET_ACTION_PANE_ID) as HTMLElement;
    actionPaneEl.replaceChildren()

    // Get all SVG elements.
    const lineDivs = document.querySelectorAll('.reference-line');
    console.log('lineDivs', lineDivs)
    lineDivs.forEach((div) => {
        div.parentNode.removeChild(div);
    });



}
