import ScheduleInputRow from "../ScheduleInputRow";

const registerScheduleInputRowDirective = (Alpine) => {
    return Alpine.directive('scheduleiputrow', (el, { expression }, { evaluate }) => {
        // console.log('registering row directive', el, expression, evaluate);

        let _row = parseInt(el.dataset.row);
        let _groupL3Id = el.dataset.groupL3Id;
        let _groupL2Id = el.dataset.groupL2Id;
        let _groupL1Id = el.dataset.groupL1Id;


        Alpine.bind(el, {
            'x-data'() {
                return {
                    row: _row,
                    groupL3Id: _groupL3Id,
                    groupL2Id: _groupL2Id,
                    groupL1Id: _groupL1Id,
                    get spreadsheetScheduleInputRow() {
                        return new ScheduleInputRow(this.row);
                    },
                };
            },
            'x-show'() {
                // return Alpine.store('sheet').rowIsVisible(this.spreadsheetScheduleInputRow);
            },
        });
    });
};

export { registerScheduleInputRowDirective };
