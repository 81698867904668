import {CustomAlpine} from "../sheets/global";

const initializeState = (Alpine: CustomAlpine, e) => {
    const cardId = e.dataset.id;

    const cardElement = document.querySelector(`[x-card][data-id="${cardId}"]`);
    cardElement.querySelector('.action-card__content')?.addEventListener('mouseenter', function (e) {
        Alpine.store('cards')[cardId].openDropdown();
    });
    cardElement.querySelector('[x-show="isDropdownOpen"]')?.addEventListener('mouseleave', function (e) {
        Alpine.store('cards')[cardId].closeDropdown();
    });
    cardElement.querySelector('[x-show="isDropdownOpen"]')?.addEventListener('click', function (e) {
        if (e.target.tagName != 'INPUT'
            && e.target.tagName != 'SELECT'
            && e.target.tagName != 'BUTTON'
            && !e.target.classList.contains('slider')
            && !e.target.classList.contains('search_clear')
        ) {
            Alpine.store('cards')[cardId].closeDropdown();
        }
    });


    Alpine.store('cards')[cardId] = {
        cardId: cardId,
        dropdownOpen: false,
        init() {
        },
        toggleDropdown() {
            if (this.dropdownOpen) {
                this.closeDropdown();
            } else {
                this.openDropdown();
            }
        },
        openDropdown() {
            this.dropdownOpen = true;
            for (let id in Alpine.store('cards')) {
                if (id !== this.cardId) {
                    Alpine.store('cards')[id].dropdownOpen = false;
                }
            }
        },
        closeDropdown() {
            this.dropdownOpen = false;
        },
        underlineFirstLetter(menuTitleElement) {
            if (menuTitleElement) {
                const firstLetter = menuTitleElement.textContent.trim().charAt(0);
                const restOfTitle = menuTitleElement.textContent.trim().substring(1);

                menuTitleElement.innerHTML = `<u>${firstLetter}</u>${restOfTitle}`;
            }
        },
        navigateOptions(direction) {
            // NEED TO HAVE "option" in class, even for initial items
            const cardElement = document.querySelector(`[x-card][data-id="${this.cardId}"]`);
            const options = Array.from(cardElement.querySelectorAll('.option'));
            if (!options.length) return;

            let currentIndex = options.findIndex(option => option === document.activeElement);
            let nextIndex;

            if (direction === 'ArrowUp') {
                nextIndex = (currentIndex - 1 + options.length) % options.length;
            } else if (direction === 'ArrowDown') {
                nextIndex = (currentIndex + 1) % options.length;
            }

            options[nextIndex].focus();
        },


    };


}

const initializeCard = (Alpine: CustomAlpine) => {
    return Alpine.directive('card', (el) => {
        initializeState(Alpine, el);
        const card_id = el.dataset.id;

        Alpine.bind(el, {
            'x-data'() {
                return {
                    get isDropdownOpen() {
                        return Alpine.store('cards')[card_id].dropdownOpen
                    },
                };
            },
        });


    });
}

export {
    initializeCard,
};
