import registerScheduleInputs from "./components/schedule_inputs";
import registerCards from "./components/cards";
import registerAllCards from "./components/allcards";
import registerSearch from "./components/search";
import registerComboBox from "./components/combobox";
import Alpine from "alpinejs";
import { CustomAlpine } from "./components/global";
import { registerHtmx } from "./components/utilities/htmx";

window.Alpine = Alpine as CustomAlpine;
console.log('Script loaded: ' + new Date().getTime());

/* ********* Begin Register Components ********* */


registerHtmx(window)
registerScheduleInputs(window.Alpine)
registerCards(window.Alpine)
registerAllCards(window.Alpine);
registerSearch(window.Alpine)
registerComboBox(window.Alpine)

/* ********** End Register Components ********** */


window.Alpine.start();

console.log('Script ended: ' + new Date().getTime());
