import Alpine from 'alpinejs'
import { CustomAlpine } from '../sheets/global';
import {initializeComboBox} from "./combobox";


if (!Alpine.store('combobox')) {
    Alpine.store('combobox', {});
}
const registerComboBox = (Alpine: CustomAlpine) => {
    initializeComboBox(Alpine)

}

export default registerComboBox
