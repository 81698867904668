import Alpine from 'alpinejs'
import { CustomAlpine } from '../sheets/global';
import { initializeAllCard } from './allcards';


const registerAllCards = (Alpine: CustomAlpine) => {
    initializeAllCard(Alpine)

}

export default registerAllCards
