import Alpine from 'alpinejs'
import { CustomAlpine } from '../sheets/global';
import { initializeCard } from './cards';


if (!Alpine.store('cards')) {
    Alpine.store('cards', { 'All': { altPressedLast: false } });
}
const registerCards = (Alpine: CustomAlpine) => {
    initializeCard(Alpine)

}

export default registerCards
