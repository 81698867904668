import { CustomAlpine } from "../sheets/global"

const initializeState = (Alpine: CustomAlpine) => {
    Alpine.store('allCards', {
        altPressedLast: false,
        activeCard: null,
        init() {},
        underlineFirstLetter(menuTitleElement) {
            if (menuTitleElement) {
                const firstLetter = menuTitleElement.textContent.trim().charAt(0)
                const restOfTitle = menuTitleElement.textContent.trim().substring(1)

                if (this.altPressedLast) {
                    menuTitleElement.innerHTML = `<u>${firstLetter}</u>${restOfTitle}`
                } else {
                    menuTitleElement.innerHTML = `${firstLetter}${restOfTitle}`
                }
            }
        },
        removeUnderlineFromElements() {
            const menuTitleElements = document.querySelectorAll('[menu-title]')
            menuTitleElements.forEach(menuTitleElement => {
                // Check if the first character is underlined
                const isUnderlined = menuTitleElement.innerHTML.startsWith('<u>')
                if (isUnderlined) {
                    const firstLetter = menuTitleElement.textContent.trim().charAt(0)
                    const restOfTitle = menuTitleElement.textContent.trim().substring(1)
                    menuTitleElement.innerHTML = `${firstLetter}${restOfTitle}`
                }
            })
        },

        closeAllCardsDropdowns() {
            let cardsStore = Alpine.store('cards')
            for (let cardKey in cardsStore) {
                if (cardsStore.hasOwnProperty(cardKey) && cardsStore[cardKey].dropdownOpen) {
                    cardsStore[cardKey].dropdownOpen = false
                }
            }
        },
    })

    window.addEventListener('altKeyHighlight', (event) => {
        const allCardsStore = Alpine.store('allCards');
        toggleAltPressedFlag(allCardsStore);
    });

    document.addEventListener('keydown', handleKeyDownEvent);

    function handleKeyDownEvent(event) {
        const allCardsStore = Alpine.store('allCards');
        const cardsStore = Alpine.store('cards');

        let activeCard = allCardsStore.activeCard;

        if (activeCard) {
            event.stopPropagation();
            handleActiveCardKeyEvent(event, activeCard, cardsStore);
        }

        if (event.key === 'm' && allCardsStore.altPressedLast) {
            openCardDropdown('model', allCardsStore, cardsStore);
        } else if (event.key === 'a' && allCardsStore.altPressedLast) {
            openCardDropdown('actions', allCardsStore, cardsStore);
        } else if (event.key === 's' && allCardsStore.altPressedLast) {
            openCardDropdown('entity_structure', allCardsStore, cardsStore);
        }

        if (event.key === 'Escape') {
            handleEscapeKeyEvent(allCardsStore);
        }
    }

    function handleActiveCardKeyEvent(event, activeCard, cardsStore) {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            cardsStore[activeCard.cardId].navigateOptions(event.key);
        } else if (event.key === 'Enter') {
            handleEnterKeyEvent(activeCard);
        }
    }

    function handleEnterKeyEvent(activeCard) {
        const focusedOption = document.activeElement;

        if (focusedOption) {
            handleFocusedOptionClick(focusedOption);

            Alpine.store("allCards").activeCard = null;
            Alpine.store("allCards").removeUnderlineFromElements();
            Alpine.store('allCards').altPressedLast = false;
        }
    }

    function handleFocusedOptionClick(focusedOption) {
        let actionTriggered = false;

        const clickAction = focusedOption.getAttribute('@click');
        if (clickAction) {
            focusedOption.click();
            actionTriggered = true;
        } else {
            handleNestedClickElement(focusedOption, actionTriggered);
        }

        // If no Alpine action was triggered, check if there's an anchor tag to click
        if (!actionTriggered) {
            if (focusedOption.classList.contains('option')) {
                const link = focusedOption.querySelector('a');
                if (link) {
                    link.click();
                }
            }
        }
    }

    function handleNestedClickElement(focusedOption, actionTriggered) {
        const nestedClickElement = focusedOption.querySelector('[\\@click]');
        if (nestedClickElement) {
            const nestedClickAction = nestedClickElement.getAttribute('@click');
            if (nestedClickAction) {
                // Alpine.evaluate(nestedClickElement, nestedClickAction);

                actionTriggered = true;

                // Extract URL from hx-get attribute
                const hxGetAttribute = nestedClickElement.getAttribute('hx-get');
                if (hxGetAttribute) {
                    const cell = { column: "YourColumnValueHere" }; // replace with actual value source if needed

                    const url = `${hxGetAttribute}`;
                    sendHtmxAjaxRequest(url);
                }
                document.getElementById('full-screen-modal').visible = true;
            }
        }
    }
    function sendHtmxAjaxRequest(url) {
        window.htmx.ajax(
            'GET',
            url,
            {
                target: '#full-screen-modal-content',
                swap: 'innerHTML',
            }
        ).then(() => {
            // Add any post-request logic here if needed
        });
    }

    function toggleAltPressedFlag(allCardsStore) {
        allCardsStore.altPressedLast = !allCardsStore.altPressedLast;
        const menuTitleElements = document.querySelectorAll('[menu-title]');
        menuTitleElements.forEach(menuTitleElement => {
            allCardsStore.underlineFirstLetter(menuTitleElement);
        });
    }

    function openCardDropdown(cardName, allCardsStore, cardsStore) {
        let card = cardsStore[cardName];
        if (card) {
            card.toggleDropdown();
            allCardsStore.activeCard = card;
            allCardsStore.removeUnderlineFromElements();
            allCardsStore.altPressedLast = false;
        }
    }

    function handleEscapeKeyEvent(allCardsStore) {
        allCardsStore.closeAllCardsDropdowns();
        allCardsStore.activeCard = null;
        allCardsStore.removeUnderlineFromElements();
        allCardsStore.altPressedLast = false;
    }





}

const initializeAllCard = (Alpine: CustomAlpine) => {
    initializeState(Alpine)
}

export {
    initializeAllCard,
}
