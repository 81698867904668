
export default class ScheduleInputRow {
    public rowLimit?: number;
    constructor(public number: number) {}

    isEqualTo(row: ScheduleInputRow): boolean {
        return this.number === row.number;
    }


    clone(): ScheduleInputRow {
        return new ScheduleInputRow(this.number);
    }

    isInGroup(level: Number, groupId: String): boolean {
        if (level == 3) {
            return this.l3Group == groupId
        } else if (level == 2) {
            return this.l2Group == groupId
        } else if (level == 1) {
            return this.l1Group == groupId
        } else {
            return false;
        }
    }


    get l3Group(): String{
        return this.element()!= null ? this.element().dataset.groupL3Id : null;
    }
    get l2Group(): String{
        return this.element()!= null ? this.element().dataset.groupL2Id : null;
    }
    get l1Group(): String{
        return this.element()!= null ? this.element().dataset.groupL1Id : null;
    }

    element(): HTMLElement|null {
        const query = `tr[data-scheduleinputrow="${this.number}"]`
        return document.querySelector(query)
    }
}
