import {CustomAlpine} from "../sheets/global";

const initializeState = (Alpine: CustomAlpine, e) => {
    // const searchId = e.dataset.searchId;
    const caseSensitive = e.dataset.caseSensitive ?? false;
    const displayMode = e.dataset.displayMode ?? 'block';
    const hideAllOnEmptyInput = e.dataset.hideAllOnEmptyInput != null
    const setSearchFromClick = e.dataset.setSearchFromClick != null
    const hideExactMatch = e.dataset.hideExactMatch != null
    const searchInput = e.tagName == 'INPUT' ? e : e.querySelector(`input[type="search"]`);
    const searchClears = e.querySelectorAll('.search_clear');
    const addlTargetClass = e.dataset.xSearch ?? '';

    function doSearch(searchString) {
        const caseSearchString = caseSensitive ? searchString : searchString.toLowerCase();

        // Check the display mode set in the data-display-mode attribute
        const isTableRowDisplay = e.getAttribute('data-display-mode') === 'table-row';

        if (isTableRowDisplay) {
            // Table row display logic
            e.querySelectorAll('tbody tr').forEach(function (row) {
                const matchesSearch = Array.from(row.querySelectorAll('td[data-search-text]')).some(function (td) {
                    const targetString = caseSensitive ? td.dataset.searchText : td.dataset.searchText.toLowerCase();
                    return targetString.includes(caseSearchString);
                });

                if (matchesSearch || (caseSearchString === '' && !hideAllOnEmptyInput)) {
                    row.style.display = 'table-row'; // Show the row
                } else {
                    row.style.display = 'none'; // Hide the row
                }
            });
        } else {
            // Flex display logic (or other non-table-row logic)
            e.querySelectorAll('[data-search-text]').forEach(function (target) {
                const targetString = caseSensitive ? target.dataset.searchText : target.dataset.searchText.toLowerCase();
                let effectiveDisplayMode = displayMode;

                if (caseSearchString === '' && hideAllOnEmptyInput) {
                    target.style.display = 'none';
                } else {
                    if (targetString.includes(caseSearchString)) {
                        target.style.display = effectiveDisplayMode;
                    } else {
                        target.style.display = 'none';
                    }
                }
            });
        }
    }





    if (setSearchFromClick) {
        e.querySelectorAll(`[data-search-text], ${addlTargetClass}[data-search-text]`).forEach(function (target) {
            target.addEventListener('click', function (evt) {
                searchInput.value = target.dataset.searchText;
                doSearch(target.dataset.searchText)
            });
        });
    }

    if (searchInput != null) {
        searchInput.addEventListener('input', function (evt) {
            doSearch(evt.target.value);
            searchClears.forEach(function (clear) {
                clear.style.display = evt.target.value == '' ? 'none' : 'inherit'
            });

        });
    }

    searchClears.forEach(function (clear) {
        clear.style.display = 'none';

        clear.addEventListener("click", function () {
            clear.style.display = 'none';
            searchInput.value = '';
            doSearch('');
        });

    });

    // No need for a persistent store
    // Alpine.store('search')[searchId] = {
    //     term: null,
    //     init() { },
    // };
}

const initializeSearch = (Alpine: CustomAlpine) => {
    return Alpine.directive('search', (el) => {
        initializeState(Alpine, el);

        Alpine.bind(el, {
            'x-data'() {
                return {};
            },
        });
    });
}

export {
    initializeSearch,
};
