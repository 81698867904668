import { CustomAlpine } from "../sheets/global";

const initializeState = (Alpine: CustomAlpine, e) => {
    const searchInput = e.tagName == 'INPUT' ? e : e.querySelector(`input[type="search"]`);
    const searchClears = e.querySelectorAll('.search_clear');
    const expansionToggle = e.querySelectorAll('expansion-toggle');
    const caseSensitive = e.dataset.caseSensitive ?? false;
    const displayMode = e.dataset.displayMode ?? 'block';
    const hideAllOnEmptyInput = e.dataset.hideAllOnEmptyInput != null;
    const setSearchFromClick = e.dataset.setSearchFromClick != 'false';
    const hideExactMatch = e.dataset.hideExactMatch != null;
    const matchBehavior = (e.dataset.matchBehavior ?? null) == 'highlight' ? 'highlight' : 'display';
    const matchAttribute = e.dataset.matchAttribute ?? 'class';
    const matchValue = e.dataset.matchValue ?? 'match';
    const addlTargetClass = e.dataset.xSearch ?? '';
    let currentSelectionIndex = -1;

    function doSearch(searchString) {
        const caseSearchString = caseSensitive ? searchString : searchString.toLowerCase();
        e.querySelectorAll(`[data-search-text], ${addlTargetClass}[data-search-text]`).forEach(function (target) {
            if (caseSearchString === '' && hideAllOnEmptyInput && matchBehavior == 'display') {
                target.style.display = 'none';
            } else {
                const targetString = caseSensitive ? target.dataset.searchText : target.dataset.searchText.toLowerCase();
                if (targetString.includes(caseSearchString) && !(targetString === caseSearchString && hideExactMatch)) {
                    if (matchBehavior == 'display') {
                        target.style.display = displayMode;
                    } else if (matchBehavior == 'highlight') {
                        if (matchAttribute == 'class') {
                            target.classList.add(matchValue);
                        } else {
                            target.setAttribute(matchAttribute, matchValue);
                        }
                    }
                } else {
                    if (matchBehavior == 'display') {
                        target.style.display = 'none';
                    } else if (matchBehavior == 'highlight') {
                        if (matchAttribute == 'class') {
                            target.classList.remove(matchValue);
                        } else {
                            target.removeAttribute(matchAttribute);
                        }
                    }
                }
            }
        });
    }

    function hideAll() {
        e.querySelectorAll(`[data-search-text], ${addlTargetClass}[data-search-text]`).forEach(function (target) {
            target.style.display = 'none';
        });
    }

    function selectItem(index) {
        const items = e.querySelectorAll(`[data-search-text]:not([style*="display: none"])`);
        if (items.length > 0) {
            items.forEach(item => item.style.backgroundColor = ''); // Remove highlight style
            currentSelectionIndex = (index + items.length) % items.length; // Handle wrapping
            items[currentSelectionIndex].style.backgroundColor = '#d3d3d3'; // Highlight color
            items[currentSelectionIndex].scrollIntoView({ block: 'nearest' });
        }
    }

    if (setSearchFromClick) {
        e.querySelectorAll(`[data-search-text], ${addlTargetClass}[data-search-text]`).forEach(function (target) {
            target.addEventListener('click', function (evt) {
                searchInput.value = target.dataset.searchText;
                doSearch(target.dataset.searchText);
                expansionToggle.forEach((p) => {
                    p.collapse();
                });
                searchClears.forEach((clear) => {
                    clear.style.display = 'inherit';
                });
            });
        });
    }

    searchInput.addEventListener('input', function (evt) {
        doSearch(evt.target.value);
        expansionToggle.forEach((p) => {
            p.expand();
        });
        searchClears.forEach(function (clear) {
            clear.style.display = evt.target.value == '' ? 'none' : 'inherit';
        });
    });

    searchClears.forEach(function (clear) {
        clear.style.display = 'none';
        clear.addEventListener("click", function () {
            clear.style.display = 'none';
            searchInput.value = '';
            doSearch('');
            expansionToggle.forEach((p) => {
                p.collapse();
                p.expand();
            });
        });
    });

    document.addEventListener('keydown', function (evt) {
        if (evt.key === 'Escape') {
            evt.stopPropagation(); // Prevent other Escape key overrides
            expansionToggle.forEach((p) => {
                p.collapse();
            });
        // } else if (evt.key === 'ArrowDown') {
        //     evt.preventDefault();
        //     selectItem(currentSelectionIndex + 1);
        // } else if (evt.key === 'ArrowUp') {
        //     evt.preventDefault();
        //     selectItem(currentSelectionIndex - 1);
        }
    });
};

const initializeComboBox = (Alpine: CustomAlpine) => {
    return Alpine.directive('combobox', (el) => {
        initializeState(Alpine, el);
        Alpine.bind(el, {
            'x-data'() {
                return {};
            },
        });
    });
};

export {
    initializeComboBox,
};
