import Alpine from 'alpinejs'
import { CustomAlpine } from '../sheets/global';
import { initializeSearch } from './search';


if (!Alpine.store('search')) {
    Alpine.store('search', {});
}
const registerSearch = (Alpine: CustomAlpine) => {
    initializeSearch(Alpine)

}

export default registerSearch
