import { CustomAlpine } from '../sheets/global';
import ScheduleInputRow from "./ScheduleInputRow";

export class ScheduleInput {
    public Alpine: CustomAlpine

    constructor(
        public store: any = null
    ) {
    }


}
